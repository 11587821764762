<style lang="less" scoped>
  .assets-data {
    background-color: #d9d9d9;
    padding: 45px;
    box-sizing: border-box;
  }
</style>

<template>
  <sign-page
    :title-text="title"
    :request="request"
    :column-list="columnList"
    @tableAction="tableAction"
    :table-actions="tableActions"
    :title-menus="titleMenus"
    @clickTitleMenu="clickTitleMenu"
    :tableActionsFixed="true"
    table-size="small"
    ref="assetsPage"
    :need-data-file="true">
    <fm-modal v-model="modalLedger" width="90%" theme="mh-blackt">
      <div style="width: 100%;height: 80vh" v-if="chooseData && chooseData.id">
        <ledger :assets-id="chooseData.id" v-if="modalLedger"></ledger>
      </div>
    </fm-modal>
    <fm-modal :mask-closable="false" v-model="modal" width="1100px" title="资产信息" theme="mh-blackt">
      <fm-form :inline="4" label-alone label-align="left" v-if="chooseData && addType ==='batch_add_all'" style="position: relative;">
        <fm-form-item label="批量新增资产条数">
          <fm-input-new v-model="chooseData.totalNum"
            :required="true"
            v-verifier
            :rules="{type: 'integer'}"
            placeholder="资产编号" :disabled="isRead"/>
        </fm-form-item>
      </fm-form>
      <fm-title title-text="资产基本信息" :note-text="noteText"></fm-title>
      <fm-form :inline="4" label-alone label-align="left" v-if="chooseData" style="position: relative;">
        <fm-form-item label="生成资产编号" v-if="!chooseData.id">
          <fm-switch
            size="mini"
            :disabled="addType ==='batch_add_all'"
            v-model="chooseData.generateCode">
            <span slot="on">自动生成</span>
            <span slot="off">手动录入</span>
          </fm-switch>
        </fm-form-item>
        <fm-form-item label="资产编号" v-if="chooseData.id || !chooseData.generateCode">
          <fm-input-new v-model="chooseData.code"
            :required="true"
            v-verifier
            placeholder="资产编号" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="项目名称">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.goodsId" :disabled="isRead">
            <fm-option v-for="item in $store.getters.goodsList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="资产名称">
          <fm-input-new v-model="chooseData.name" v-verifier placeholder="资产名称" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="资产归属情况">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.beloneType" :disabled="isRead">
            <fm-option v-for="item in ['国有资产', '单位资产']" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="预算项目编号">
          <fm-input-new v-model="chooseData.budgetCode" placeholder="预算项目编号" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="采购组织形式">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.buyType" :disabled="isRead">
            <fm-option v-for="item in ['政府集中采购', '部门集中采购', '分散采购', '其他']" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="取得方式">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.getType" :disabled="isRead">
            <fm-option v-for="item in ['新购', '调拨', '接受捐赠', '置换', '盘盈', '其他']" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="取得日期">
          <fm-date-picker
            absolute
            :readonly="isRead"
            v-verifier
            :value="chooseData.getTime"
            @change="(getTime) => {chooseData.getTime = getTime ? getTime.replace(/年|月/g, '-').replace('日', '') : null}"
            :clearable="!isRead"
            placeholder="请选择"
            type="date"
            format="Y年M月D日"
          ></fm-date-picker>
        </fm-form-item>
        <fm-form-item label="设备用途">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.assetsUse" :disabled="isRead">
            <fm-option v-for="item in ['办公', '医疗', '教学', '培训', '执法', '窗口', '专业', '科研', '其他']" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="品牌">
          <fm-input-new v-model="chooseData.pp" placeholder="品牌" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="规格型号">
          <fm-input-new v-model="chooseData.ggxh" placeholder="规格型号" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="产品序列号">
          <fm-input-new v-model="chooseData.cpxlh" placeholder="产品序列号" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="生产厂家">
          <fm-input-new v-model="chooseData.sccj" placeholder="生产厂家" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="销售商">
          <fm-input-new v-model="chooseData.xss" placeholder="销售商" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="投入使用日期">
          <fm-date-picker
            absolute
            :readonly="isRead"
            :value="chooseData.startUseTime"
            @change="(startUseTime) => {chooseData.startUseTime = startUseTime ? startUseTime.replace(/年|月/g, '-').replace('日', '') : null}"
            :clearable="!isRead"
            placeholder="请选择"
            type="date"
            format="Y年M月D日"
          ></fm-date-picker>
        </fm-form-item>
        <fm-form-item label="关联采购结果">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.purchaseId" :disabled="isRead">
            <fm-option v-for="item in purchaseList" :key="item.id" :value="item.id" :label="item.code + ' ' + item.goodsName + ' ' + item.pp + ' ' + item.ggxh"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="保修截止日期">
          <fm-date-picker
            absolute
            :readonly="isRead"
            :value="chooseData.bxEndTime"
            @change="(bxEndTime) => {chooseData.bxEndTime = bxEndTime ? bxEndTime.replace(/年|月/g, '-').replace('日', '') : null}"
            :clearable="!isRead"
            placeholder="请选择"
            type="date"
            format="Y年M月D日"
          ></fm-date-picker>
        </fm-form-item>
        <fm-form-item label="发票号">
          <fm-input-new v-model="chooseData.fpCode" placeholder="发票号" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="折旧年限（月）">
          <fm-input-new v-model="chooseData.zjnx" placeholder="折旧年限（月）" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="配置标准分类名称">
          <fm-input-new v-model="chooseData.pzbzflmc" placeholder="配置标准分类名称" :disabled="isRead"/>
        </fm-form-item>
      </fm-form>
      <fm-title title-text="资产财务信息"></fm-title>
      <fm-form :inline="4" label-alone label-align="left" v-if="chooseData" style="position: relative;">
        <!-- <fm-form-item label="价值类型">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.jzlx" :disabled="isRead">
            <fm-option v-for="item in ['市场价值', '在用价值', '投资价值', '持续经营价值', '清算价格', '保险价值', '课税价值']" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item> -->
        <fm-form-item label="价值类型">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.jzlx" :disabled="isRead">
            <fm-option v-for="item in ['原值', '暂估值', '重置值', '评估值', '名义金额']" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="价值">
          <fm-input-new v-model="chooseData.jz" v-verifier :rules="{type: 'number'}" placeholder="价值" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="财政拨款">
          <fm-input-new v-model="chooseData.czbk" placeholder="财政拨款" v-verifier :rules="{type: 'number'}" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="非财政拨款">
          <fm-input-new v-model="chooseData.fczbk" placeholder="非财政拨款" v-verifier :rules="{type: 'number'}" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="卡片状态">
          <fm-select v-verifier absolute filterable :clearable="!isRead" v-model="chooseData.cwrzzt" :disabled="isRead">
            <fm-option v-for="item in ['入账', '暂存']" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="财务入账日期">
          <fm-date-picker
            absolute
            v-verifier
            :readonly="isRead"
            :value="chooseData.cwrzrq"
            @change="(cwrzrq) => {chooseData.cwrzrq = cwrzrq ? cwrzrq.replace(/年|月/g, '-').replace('日', '') : null}"
            :clearable="!isRead"
            placeholder="请选择"
            type="date"
            format="Y年M月D日"
          ></fm-date-picker>
        </fm-form-item>
        <fm-form-item label="会计凭证号">
          <fm-input-new v-model="chooseData.kjpsh" v-verifier placeholder="会计凭证号" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="折旧/摊销状态">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.zjzt" :disabled="isRead">
            <fm-option v-for="item in ['已折旧', '未折旧', '提折旧', '不提折旧', '已完成折旧']" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="账面净值">
          <fm-input-new v-model="chooseData.zmjz" placeholder="账面净值" v-verifier :rules="{type: 'number'}" :disabled="isRead"/>
        </fm-form-item>
      </fm-form>
      <fm-title title-text="资产使用信息"></fm-title>
      <fm-form :inline="4" label-alone label-align="left" v-if="chooseData" style="position: relative;">
        <fm-form-item label="使用状态">
          <fm-select absolute filterable v-verifier :clearable="!isRead" v-model="chooseData.useStatus" :disabled="isRead">
            <fm-option v-for="item in ['在用', '仓储', '报废']" :key="item" :value="item" :label="item"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="使用机构">
          <fm-select absolute filterable :clearable="!isRead" v-model="chooseData.useOrgId" :disabled="isRead">
            <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="使用人">
          <fm-select absolute filterable :clearable="!isRead" v-model="chooseData.useWorkerId" :disabled="isRead">
            <fm-option v-for="item in workerList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="管理机构">
          <fm-select absolute filterable :clearable="!isRead" v-model="chooseData.manageOrgId" :disabled="isRead">
            <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="存放地点">
          <fm-input-new v-model="chooseData.cfdd" placeholder="存放地点" :disabled="isRead"/>
        </fm-form-item>
        <fm-form-item label="备注">
          <fm-input-new v-model="chooseData.bz" placeholder="备注" :disabled="isRead"/>
        </fm-form-item>
      </fm-form>
      <div class="modal-footer-btns" v-if="!isRead">
        <fm-btn @click="save" :v-loadingx="loading" v-if="$authFunsProxy.edit_all || $authFunsProxy.add_all">确定</fm-btn>
        <fm-btn @click="modal = false">取消</fm-btn>
      </div>
    </fm-modal>
    <fm-modal v-model="print" title="资产标签">
      <div class="assets-data" style="display: flex;justify-content: center;" v-if="chooseData">
        <div ref="label" style="display: inline-block;font-size: 12px; width: 5cm; height: 3cm;padding: 0.1cm;background-color: #FFF;border-radius: 5px;color: #000;">
          <div style="font-weight: 800;text-align: center;">太湖县妇幼保健院</div>
          <div>{{chooseData.name}} （{{chooseData.jz}}元）</div>
          <div style="display: flex;justify-content: space-between;">
            <span>使用部门: {{chooseData.useOrgName}}</span>
            <!-- <span>设备用途: {{chooseData.use}}</span> -->
          </div>
          <div>投入使用日期: {{(chooseData.startUseTime || '').slice(0, 10)}}</div>
          <!-- <div style="display: flex;justify-content: space-between;">
            <span>使用人: {{chooseData.workerName}}</span>
            <span>管理机构: {{chooseData.manageOrgName}}</span>
          </div> -->
          <!-- <div>存放地点: {{chooseData.cfdd}}</div> -->
          <img ref="barcode-img" src="" style="display: block;width: 100%; height: 0.6cm;">
          <div style="display: flex;justify-content: space-around;">
            <span>{{chooseData.code}}</span>
            <span>{{chooseData.ggxh}}</span>
          </div>
        </div>
      </div>
      <canvas ref="barcode" style="display: none;"></canvas>
      <iframe ref="print-iframe" style="display: none;"></iframe>
      <div slot="footer" style="display: flex;justify-content: space-around;">
        <fm-btn @click="printBarCode">打印</fm-btn>
        <fm-btn @click="print = false">取消</fm-btn>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  assetsRequest as request,
  purchaseRequest
} from '@/api'

import ledger from './ledger/index'

const JsBarcode = require('jsbarcode')

export default {
  components: {
    ledger
  },
  props: {
    title: {
      type: String,
      default: '全部资产'
    },
    loadParm: {
      type: Object,
      default: null
    }
  },
  created () {
    if (this.$authFunsProxy.add_all || this.$authFunsProxy.edit_all || this.$authFunsProxy.detail) {
      this.$store.dispatch('loadOrgList')
      this.$store.dispatch('loadWorkerList')
      this.$store.dispatch('loadGoodsList')
      this.$store.dispatch('loadAssetsTypeList')
      this.loadPurchase()
    }
  },
  methods: {
    async loadPurchase () {
      this.purchaseList = await purchaseRequest.get()
    },
    async loadData (parm) {
      return await request.get(Object.assign({}, parm, this.loadParm))
    },
    printBarCode () {
      this.$refs['print-iframe'].contentWindow.print()
    },
    chooseAssetsType (data) {
      this.chooseData.type = data.name
      this.modalAssetsType = false
    },
    tableAction (parm) {
      this.chooseData = JSON.parse(JSON.stringify(parm.data))
      if (parm.action === 'edit_all') {
        this.isRead = false
        this.modal = true
      } else if (parm.action === 'detail') {
        this.isRead = true
        this.modal = true
      } else if (parm.action === 'ledger') {
        this.modalLedger = true
      } else if (parm.action === 'label') {
        this.print = true
        this.$nextTick(() => {
          JsBarcode(this.$refs.barcode, this.chooseData.code, {
            displayValue: false,
            margin: 0,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            height: 60
          })
          this.$refs['barcode-img'].src = this.$refs.barcode.toDataURL('image/png')
          this.$refs['print-iframe'].srcdoc = '<body>' + this.$refs.label.outerHTML + '</body>'
        })
      }
    },
    clickTitleMenu (key) {
      this.addType = key
      this.chooseData = {
        generateCode: true,
        totalNum: 1
      }
      this.isRead = false
      this.modal = true
    },
    async save () {
      if (!this.chooseData.id && this.chooseData.generateCode) {
        this.chooseData.code = null
      } else {
        this.chooseData.totalNum = null
      }
      if (this.addType === 'batch_add_all') {
        if (!this.chooseData.totalNum || isNaN(this.chooseData.totalNum)) {
          this.$notice.info({
            title: '系统提示',
            desc: '请输入正确的批量新增资产条数。'
          })
          return
        }
        let num = Number(Number(this.chooseData.totalNum).toFixed(0))
        if (num < 1) {
          this.$notice.info({
            title: '系统提示',
            desc: '请输入正确的批量新增资产条数。'
          })
          return
        }
      }
      let pass = true
      let numKeys = ['jz', 'czbk', 'fczbk', 'zmjz', 'totalNum']
      numKeys.forEach((key) => {
        if (this.chooseData[key] && (isNaN(this.chooseData[key]) || this.chooseData[key] === ' ')) {
          this.$notice.info({
            title: '系统提示',
            desc: '请输入正确的数值。'
          })
          pass = false
        }
      })
      if ((this.chooseData.id || !this.chooseData.generateCode) && !this.chooseData.code) {
        this.$notice.info({
          title: '系统提示',
          desc: '请输入编码'
        })
        pass = false
      }
      if (this.$refs.assetsPage.dataList.find(v => v.code === this.chooseData.code && v.id !== this.chooseData.id)) {
        this.$notice.info({
          title: '系统提示',
          desc: '编码重复，请重新输入'
        })
        pass = false
      }
      // this.columnList.filter(v => v.field !== 'goodsName').forEach(({field, title}) => {
      //   if (!this.chooseData[field]) {
      //     this.$notice.info({
      //       title: '系统提示',
      //       desc: title + '不能为空。'
      //     })
      //     pass = false
      //   }
      // })
      if (!pass) {
        return
      }
      this.loading = true
      this.chooseData.workerId = this.workerId || null
      this.chooseData.startUseTime = this.chooseData.startUseTime || null
      if (this.chooseData.id) {
        await request.update(this.chooseData.id, this.chooseData)
        this.$notice.info({
          title: '系统提示',
          desc: '资产信息修改完成。'
        })
      } else {
        await request.add(this.chooseData)
        this.$notice.info({
          title: '系统提示',
          desc: '资产信息新增完成。'
        })
      }
      this.modal = false
      this.loading = false
      this.$refs.assetsPage.loadData()
    }
  },
  computed: {
    titleMenus () {
      return [{key: 'add_all', label: '新增'}, {key: 'batch_add_all', label: '批量新增'}]
    },
    noteText () {
      let data = ''
      if (this.chooseData && this.chooseData.id && this.isRead) {
        data += '信息维护人：' + this.chooseData.workerName
        data += '       信息维护日期：' + (this.chooseData.statusTime || this.chooseData.createTime).slice(0, 10)
      }
      return data
    },
    workerId () {
      return (this.$store.getters.userPrevilege.find(v => v.objType === 'worker') || {objId: null}).objId
    },
    orgList () {
      return this.$store.getters.orgList
    },
    workerList () {
      return this.$store.getters.workerList
    },
    tableActions () {
      return [{
        key: 'label',
        label: '资产标签'
      },
      {
        key: 'ledger',
        label: '台账'
      },
      {
        key: 'detail',
        label: '明细'
      },
      {
        key: 'edit_all',
        label: '维护'
      },
      {
        key: 'del',
        label: '删除'
      }].filter(v => this.$authFunsProxy[v.key])
    },
    columnList: {
      get () {
        let data = [{
          title: '资产编号',
          sort: true,
          width: 120,
          fixed: 'left',
          field: 'code'
        },
        {
          title: '项目名称',
          fixed: 'left',
          sort: true,
          field: 'goodsName'
        },
        // {
        //   title: '盘点',
        //   fixed: 'left',
        //   sort: true,
        //   field: 'checkTimes',
        //   render (h, row) {
        //     return h('div', {
        //       style: {
        //         color: row.checkTimes ? 'green' : 'red'
        //       }
        //     }, row.checkTimes ? '是' : '否')
        //   }
        // },
        {
          title: '资产名称',
          fixed: 'left',
          sort: true,
          field: 'name'
        },
        {
          title: '资产归属情况',
          fixed: 'left',
          sort: true,
          field: 'beloneType'
        },
        { title: '使用机构', field: 'useOrgName', sort: true },
        { title: '管理机构', field: 'manageOrgName', sort: true },
        {
          title: '取得方式',
          sort: true,
          field: 'getType'
        },
        {
          title: '取得日期',
          sort: true,
          dataType: Date,
          field: 'getTime',
          render: (h, rowData) => {
            return h('div', rowData.getTime ? rowData.getTime.slice(0, 10) : '-')
          }
        },
        {
          title: '设备用途',
          sort: true,
          field: 'assetsUse'
        },
        {
          title: '价值类型',
          sort: true,
          field: 'jzlx'
        },
        {
          title: '价值',
          dataType: Number,
          sort: true,
          field: 'jz'
        },
        {
          title: '卡片状态',
          sort: true,
          field: 'cwrzzt'
        },
        {
          title: '财务入账日期',
          sort: true,
          dataType: Date,
          field: 'cwrzrq',
          render: (h, rowData) => {
            return h('div', rowData.cwrzrq ? rowData.cwrzrq.slice(0, 10) : '-')
          }
        },
        {
          title: '会计凭证号',
          sort: true,
          field: 'kjpsh'
        },
        {
          title: '折旧/摊销状态',
          sort: true,
          field: 'zjzt'
        },
        {
          title: '使用状态',
          sort: true,
          field: 'useStatus'
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      purchaseList: [],
      print: false,
      modal: false,
      modalLedger: false,
      isRead: false,
      addType: null,
      chooseData: null,
      loading: false,
      modalAssetsType: false
    }
  }
}
</script>

<style lang="less" scoped>
.table-c {
  height: 60vh;
  width: 100%;
}
.file-d-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-d-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
</style>